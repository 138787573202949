
import Vue, { PropType } from 'vue'
import { SaEventNote } from '~/models/events'
import MarkdownElement from '~/components/markdown/Element.vue'
import AccordionElement from '~/components/_general/AccordionElement.vue'

export default Vue.extend({
  name: 'EventNoteList',
  components: { AccordionElement, MarkdownElement },
  props: {
    notes: {
      type: Array as PropType<SaEventNote[]>,
      default: () => [],
    },
  },
})
