// Tailwind is currently pinned at 3.2.7 because there is an issue with vue + tailwind ^3.3.0 where dark and vue-deep aren't playing nicely together.
// https://github.com/tailwindlabs/tailwindcss/issues/11064
// https://github.com/tailwindlabs/tailwindcss/issues/11024

module.exports = {
  darkMode: ['class', '.dark-mode'],
  content: [
    './assets/css/**/*.scss',
    './assets/ts/**/*.{js,ts}',
    './components/**/*.vue',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './plugins/**/*.{js,ts}',
  ],
  theme: {
    // If changing these values, be sure to change the TailwindBreakpoint enums
    screens: {
      xxs: '320px',
      xs: '480px',
      sm: '640px',
      md: '768px',
      xm: '896px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      white: '#fff',
      vault: '#475a69',
      psalter: '#2b314e',
      gray: {
        100: '#f7f7f7',
        200: '#eee',
        300: '#dfdfdf',
        350: '#ccc',
        400: '#aaa',
        DEFAULT: '#888',
        500: '#888',
        600: '#6e6e6e',
        650: '#505050',
        700: '#333',
        750: '#2b2b2b',
        800: '#222',
        900: '#111',
      },
      black: '#000',
      blue: {
        light: '#e6f3ff',
        highlight: '#4da8ff',
        DEFAULT: '#0082ff',
        dark: '#005bb3',
        navy: '#032B4F',
      },
      purple: {
        light: '#eae4fd',
        highlight: '#ae74db',
        DEFAULT: '#6f2ba4',
        dark: '#461b67',
      },
      red: {
        light: '#ffeaec',
        highlight: '#ff6b78',
        DEFAULT: '#ff1e32',
        dark: '#b70010',
      },
      yellow: {
        light: '#fff2af',
        highlight: '#ffe964',
        DEFAULT: '#ffc515',
        dark: '#9a6400',
      },
      admin: {
        light: '#fff2ea',
        highlight: '#ffa66b',
        DEFAULT: '#ff771e',
        dark: '#b74900',
      },
      green: {
        light: '#e7f8e9',
        highlight: '#70d67d',
        sale: '#7fc379',
        DEFAULT: '#38c14a',
        dark: '#278633',
      },
      learn: {
        highlight: '#40774d',
        DEFAULT: '#396E45',
      },
      brand: {
        facebook: '#3c5a99',
        youtube: '#ff0000',
        twitter: '#1da1f2',
        spotify: '#1db954',
        $tunein: '#1c203c',
        $apple: '#555555',
        $dropbox: '#0061ff',
        twitch: '#9146ff',
        vimeo: '#1ab7ea',
        'x-twitter': '#000000',
      },
    },
    fontFamily: {
      sans: [
        'Open Sans',
        'OpenSans',
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif',
      ],
      serif: ['serif'],
      condensed: [
        'Open Sans Condensed',
        'Open Sans',
        'OpenSans',
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif',
      ],
    },
    outline: {
      none: 'none',
      white: ['1px dashed #fff', '2px'],
      black: ['1px dashed #000', '2px'],
      blue: ['1px dashed #0082ff', '2px'],
      'blue-highlight': ['1px dashed #4da8ff', '2px'],
      gray: ['1px dashed #888', '2px'],
      red: ['1px dashed #ff1e32', '2px'],
      'red-highlight': ['1px dashed #ff6b78', '2px'],
      current: ['1px dashed currentColor', '2px'],
      'current-solid': ['1px solid currentColor', '2px'],
    },
    boxShadow: {
      // box-shadow: h-offset v-offset blur spread color |inset|initial|inherit;
      DEFAULT: '0 0 15px rgba(0,0,0,0.3)',
      bottom: '0 10px 15px -3px rgba(0,0,0,0.3)',
      lg: '0 0 25px rgba(0,0,0,0.3)',
      'inner-t-lg': 'inset 0 8px 7px -7px rgba(0, 0, 0, 0.12)',
      'inner-b-xl': 'inset 0 -12px 10px -10px rgba(0, 0, 0, 0.18)',
      'inner-b-xl-dark': 'inset 0 -12px 10px -10px rgba(0, 0, 0, 0.5)',
      none: 'none',
    },
    extend: {
      fontSize: {
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        8: '2rem',
        10: '2.5rem',
        12: '3rem',
        14: '3.5rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        28: '7rem',
        30: '7.5rem',
        32: '8rem',
        36: '9rem',
        40: '10rem',
        44: '11rem',
        48: '12rem',
        52: '13rem',
        'min-base': 'max(1em, 16px)',
      },
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
      },
      transitionProperty: {
        size: 'height, width',
        height: 'height',
        width: 'width',
        spacing: 'margin, padding',
        position: 'left, right, top, bottom',
      },
      cursor: {
        none: 'none',
      },
      transitionDuration: {
        0: '0ms',
      },
      gridTemplateColumns: {
        '13c': 'repeat(13, minmax(0, 1fr))',
      },
      lineClamp: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
      },
      spacing: {
        '0.125em': '0.125em',
        '0.25em': '0.25em',
        '0.5em': '0.5em',
        '1em': '1em',
        '1.5em': '1.5em',
        '2em': '2em',
        '1/20': '5%',
        '1/10': '10%',
        '1/8': '12.5%',
        '3/20': '15%',
        '1/6': '16.66%',
        '1/5': '20%',
        '1/4': '25%',
        '3/10': '30%',
        '1/3': '33.33%',
        '7/20': '35%',
        '2/5': '40%',
        '9/20': '45%',
        '1/2': '50%',
        '11/20': '55%',
        '16/9': '56.25%',
        '3/5': '60%',
        '13/20': '65%',
        '2/3': '66.66%',
        '7/10': '70%',
        '3/4': '75%',
        '4/5': '80%',
        '5/6': '83.33%',
        '17/20': '85%',
        '9/10': '90%',
        '19/20': '95%',
        full: '100%',
        inherit: 'inherit',
        96: '24rem',
        128: '32rem',
        144: '36rem',
        168: '42rem',
        192: '48rem',
        'site-sidebar': '16rem',
        'site-header': '2.75rem',
      },
    },
  },
  plugins: [
    require('./plugins/tailwind/variants.js'),
    require('./plugins/tailwind/components.js'),
    require('./plugins/tailwind/matchUtilities.js'),
  ],
}
