const plugin = require('tailwindcss/plugin')

module.exports = plugin(function ({ theme, addComponents }) {
  const spacing = theme('spacing')
  addComponents({
    '.sticky-header': {
      position: 'sticky',
      'z-index': 40,
      top: spacing['site-header'],
    },
    '.ellipsis': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.flex-center': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.flex-between-center': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '.absolute-fill': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    '.fixed-fill': {
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    '.fill': {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    '.fill-x': {
      left: 0,
      right: 0,
    },
    '.fill-y': {
      top: 0,
      bottom: 0,
    },
    '.text-one-line': {
      wordSpacing: '100vw',
    },
    '.scrollbar-thin': {
      'scrollbar-width': 'thin',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
    },
    '.scrollbar-regular': {
      'scrollbar-width': 'auto',
      '&::-webkit-scrollbar': {
        width: '16px',
      },
    },
    '.scrollbar-hidden': {
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        width: '0px',
        dipslay: 'none',
      },
    },
    '.scroll-touch': {
      '-webkit-overflow-scrolling': 'touch',
    },
    '.min-h-textarea': {
      'min-height': '6.5rem',
    },
  })
})
