
import Vue, { PropType } from 'vue'
import { SaEvent } from '~/models/events'
import { PlayerMediaType } from '~/assets/ts/enums'
import { Sermon } from '~/models/sermon'
import { HSLA } from '~/assets/ts/utils/color'
import { Webcast } from '~/models/webcast'
import EventSectionHeader from '~/components/event/SectionHeader.vue'
import HashAnchor from '~/components/_general/HashAnchor.vue'
import PlayerElement from '~/components/player/Element.vue'

export default Vue.extend({
  name: 'EventVideo',
  components: { PlayerElement, HashAnchor, EventSectionHeader },
  props: {
    event: {
      type: Object as PropType<SaEvent>,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    hashOffset: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      videoSermonApi: undefined as Record<string, any> | undefined,
    }
  },
  async fetch() {
    if (!this.videoSermonID || this.webcast) return
    this.videoSermonApi = await this.$apiClient.getSermon(this.videoSermonID, {
      lite: false,
    })
  },
  computed: {
    color(): HSLA {
      return this.event.color
    },
    PlayerMediaType() {
      return PlayerMediaType
    },
    broadcasterId(): string {
      return this.event.broadcaster.id
    },
    webcast(): Webcast | undefined {
      if (!this.event.inProgress()) return undefined
      const live = this.$store.getters['webcasts/broadcasterWebcast'](
        this.broadcasterId
      )
      return live ? new Webcast(live) : undefined
    },
    videoSermonID(): string | undefined {
      return this.event.videoSermonID
    },
    videoSermon(): Sermon | undefined {
      if (this.webcast) return undefined
      if (!this.videoSermonApi) return undefined
      return new Sermon(this.videoSermonApi)
    },
  },
})
